import React, { useEffect, useState } from 'react';
import { VideoStreamRenderer } from '@azure/communication-calling'; // Adjust the import path as needed
import './App.css';
import mute_incall from './assets/mute_incall.png';
import unmute_incall from './assets/unmute_incall.png';
import raise_hand_active from './assets/raise_hand_active.png';
let globalParticount=1;
const RenderDynamicView = ({ remoteVideoStream, item, participantCount,pageIndex }) => {
  console.log('----inside dynamic view-----', remoteVideoStream, item, participantCount)
  const divId = `participant-${item.userId}`;
   //const [participantCount, setParticipantCount] = useState(remoteVideoStream.length);
  // const [isVideoOn, setIsVideoOn] = useState(item.isVideoOff);

  useEffect(() => {
    let renderer;
    let view;
    let isMounted = true;
    const videoContainer = document.getElementById(divId);

    const createView = async () => {
      console.log(participantCount,'----call on createview-----', item)
      if (videoContainer) {
        try {
          if (item.isVideoOff) {
            disposeView();
          } else {
            renderer = new VideoStreamRenderer(remoteVideoStream);
            view = await renderer.createView();
            videoContainer.innerHTML = ""; // Clear previous content
            videoContainer.appendChild(view.target);

            const shortNameElement = document.createElement('div');
        shortNameElement.className = 'short-name';
        shortNameElement.innerText = item.displayName;
        videoContainer.appendChild(shortNameElement);

          // Add the mute status element
      const muteStatusElement = document.createElement('img');
       muteStatusElement.className = 'mute-status';
      muteStatusElement.src = item.isMute ? mute_incall : unmute_incall;
      muteStatusElement.id = `mute-status-${item.userId}`;
      videoContainer.appendChild(muteStatusElement);

      // // Add the rise hand.
      const risehandStatusElement = document.createElement('img');
      risehandStatusElement.className = 'risehand-status';
      risehandStatusElement.src = raise_hand_active;
      risehandStatusElement.id = `risehand-status-${item.userId}`;
      if (item.isRiseHand)
        risehandStatusElement.style.display = 'block';
      else
        risehandStatusElement.style.display = 'none';
        videoContainer.appendChild(risehandStatusElement);
      //   console.log('----stop-----')

          }
        } catch (error) {
          console.error("Failed to render video stream:", error);
        }
      }
    };

    const disposeView = () => {
      if (view) {
        view.dispose();
      }
      if (renderer) {
        renderer.dispose();
      }
    };
    const getClass=()=>{
      console.log(globalParticount,'-----getclass-----',participantCount);
      return globalParticount <3 ? "remote-one-custom":globalParticount == 3 ? "remote-three-custom": globalParticount ==4 ? "remote-four-custom": globalParticount < 7 ? "remote-morethan-four-custom": "remote-greaterthan-six-custom"
    }
    const showCustomView = () => {
      console.log('----start custom-----',globalParticount)
      //const videoContainer = document.getElementById(divId);
      if (videoContainer) {
        videoContainer.innerHTML = "";
        videoContainer.className=getClass()
        

// Add the short name element
        const shortNameElement = document.createElement('div');
        shortNameElement.className = 'short-name';
        shortNameElement.innerText = item.displayName;
        videoContainer.appendChild(shortNameElement);

        // Add the mute status element
        const muteStatusElement = document.createElement('img');
        muteStatusElement.className = 'mute-status';
       muteStatusElement.src = item.isMute ? mute_incall : unmute_incall;
       muteStatusElement.id = `mute-status-${item.userId}`;
       videoContainer.appendChild(muteStatusElement);

      // // Add the rise hand.
      const risehandStatusElement = document.createElement('img');
      risehandStatusElement.className = 'risehand-status';
      risehandStatusElement.src = raise_hand_active;
      risehandStatusElement.id = `risehand-status-${item.userId}`;
      if (item.isRiseHand)
        risehandStatusElement.style.display = 'block';
      else
        risehandStatusElement.style.display = 'none';

        videoContainer.appendChild(risehandStatusElement);
         console.log('----custom view-----')
      }
    };

    if (remoteVideoStream) {
      createView();
      remoteVideoStream.on('isAvailableChanged', () => {
        console.log(globalParticount,'-----isAvailable----', remoteVideoStream.isAvailable)
        if (remoteVideoStream.isAvailable) {
          console.log('----onchange start video-----')
          createView();
        } else {
          disposeView();
          showCustomView()
        }
      });
    } else {
      showCustomView()
      console.log('-----else----', item);
    }

 

    // return () => {
    //   console.log('----return-----')
    //   isMounted = false;
    //   disposeView();
    // };
  }, [remoteVideoStream, divId]);

  useEffect(() => {
    console.log('----changes-item----', item)
    // Update mute and video status based on item props
    // setIsMuted(item.isforceMute);
  }, [item]);



  return (
    <div id={divId} className={globalParticount <3 ? "remote-one":globalParticount == 3 ? "remote-three": globalParticount ==4 ? "remote-four": globalParticount < 7 ? "remote-morethan-four": "remote-greaterthan-six"} ></div>

    // <div id={divId} className={participantCount == 1 && pageIndex==0 ? "remote-one": participantCount == 1 && pageIndex>0 ? "remote-one-not-istpage" : participantCount == 2 && pageIndex==0 ? "remote-two": participantCount == 2 && pageIndex> 0 ? "remote-two-not-istpage" :participantCount == 3 ? "remote-three": participantCount < 7 ? "remote-four": "remote-greaterthan-six"} >
    //   <p>{'---->>>'+item.displayName}</p>
    // </div>
    // <div className='video-pos'>
      
     
    // </div>
  );

};
export function updateUiOnDemand(senderUserId,type, typeValue) {
  if(type==='updateMuteUnmute'){
    const muteStatusElement = document.getElementById(`mute-status-${senderUserId}`);
    if (muteStatusElement) {
      muteStatusElement.src = typeValue ? mute_incall : unmute_incall;
    }
  }else if(type==='updateRiseHand'){
    const risehandStatusElement = document.getElementById(`risehand-status-${senderUserId}`);
    if (risehandStatusElement) {
      console.log('----typeValue----',typeValue)
      if(typeValue)
        risehandStatusElement.style.display = 'block';
      else
      risehandStatusElement.style.display = 'none';
    }
  }
}
export function updateCount(count) {
  globalParticount=count;
  console.log('----globalParticount----',globalParticount)
}
export default React.memo(RenderDynamicView);
