import io from 'socket.io-client';
let isEnvLocal=false; // change it false for live and true for local
//172.1.3.228
  const sockets = io( isEnvLocal ? 'localhost:5001' : 'https://odrappservicedev-dkevaqfyadbnh8cs.southindia-01.azurewebsites.net'); //, { autoConnect: true, forceNew: true } https://odr-appservice-server.azurewebsites.net
 //const sockets = io('192.168.2.26:5001'); //, { autoConnect: true, forceNew: true }

export function getBaseUrl() {
  //return isEnvLocal ? 'http://localhost:3000': 'https://happy-glacier-09f3bb400-preview.eastasia.5.azurestaticapps.net' ; //need to change
  return isEnvLocal ? 'http://localhost:3000': 'https://purple-wave-0dcda5000.5.azurestaticapps.net' ; //need to change https://dev.connectrix.in  https://odr.connectrix.in https://purple-wave-0dcda5000.5.azurestaticapps.net
}
export function getConnectionStringUrl() {
  return isEnvLocal ? "endpoint=https://odr-communicationservice-dev.india.communication.azure.com/;accesskey=2EVemOyWhULK0n4uOt9N4yCcyHtBgxeI0MBWpLKDaTKpcEngD5WAJQQJ99AGACULyCpN4bMSAAAAAZCShrWy" :
                      "endpoint=https://odr-communicationservice-customvideocall.india.communication.azure.com/;accesskey=EBeGEZfKbNarrOUYtkX95SuaPTn7xhEhb4y6tMuMJNVATymoyIn2JQQJ99AFACULyCpN4bMSAAAAAZCSgM2t"
}
export function getSoundStatus() {
  return true
}
export default sockets;
