import React, { useState, useRef, useEffect } from 'react';
import { CallClient, VideoStreamRenderer, LocalVideoStream, CallingCommunicationError, CallAutomationClient, Features } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { RoomsClient } from '@azure/communication-rooms';
import { CommunicationIdentityClient } from '@azure/communication-identity';
import './App.css';
import RenderDynamicView, { updateUiOnDemand, updateCount } from './RenderDynamicView';
import RemoteVideo from "./RemoteVideo";
import next from './assets/next.png';
import prev from './assets/prev.png';
import mute_incall from './assets/mute_incall.png';
import stop_video from './assets/stop_video.png';
import start_video from './assets/start_video.png';
import end_call from './assets/end_call.png';
import mute from './assets/mute.png';
import unmute from './assets/unmute.png';
import newmeeting from './assets/newmeeting.png';
import nextbtn from './assets/nextbtn.png';
import lobby from './assets/lobby.png';
import recording_start from './assets/recording_start.png';
import raise_hand_active from './assets/raise_hand_active.png';
import rise_hand from './assets/rise_hand.png';
import join_call from './assets/join_call.png';
import participant from './assets/participant.png';
import callbtn from './assets/callbtn.png';
import rejoin from './assets/rejoin.png';
import unmute_incall from './assets/unmute_incall.png';
import socket, { getBaseUrl, getConnectionStringUrl, getSoundStatus } from './socket';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DisplayLobbyComponent from './component/DisplayLobbyComponent';
import DisplayParticipantComponent from './component/DisplayParticipantComponent';
import { getAcsToken } from './model/acsToken';



const { sendMessageToServer } = require('./sendMessage');
const { createRoom, addParticipant } = require('./model/createRoomAcs');
const { receiveServerMessage, oneToOneMessage, updateUiOnAcceptCall, updateStatusOnUi } = require('./receiveMessage');

let isCallCreator = false, isToastShowingFlag = false;
let userAccessToken, userId, acsRoomId = '', acsusrt, callAgent, deviceManager = null, call = null, callId = '', secretId = '', displayName = '';
let stopTwiceJoinCall = true, isParticipantRemoved = false, participantCountGlobal = 1;

let clientObjectArr = [], clientPagingArr = [];
let pageIndex = 0, pageSize = 8;
let paggiStartIndex = 0, paggiLastIndex = pageSize;

let clientObj = { userId: '', userType: '', socketId: socket.id, displayName: '', isRiseHand: false, isMute: false, isVideoOff: false, isAllowRecording: false, stream: null, isAllowCamera: true, isAllowMicroPhone: true, isAcceptedByAdmin: false };
let progressId = '', usertypeFromQuery = '', userIdFromQuery = '', isParticipantOpen = false, isLobbyOpen = false;
const roomsClient = new RoomsClient(getConnectionStringUrl());
function App() {
  const modalRefForLobby = useRef(null);
  const modalRefParticipant = useRef(null);
  const [displayRemoteParticipant, setDisplayRemoteParticipant] = useState([]);
  const [displaySmsg, setDisplaySmsg] = useState({});
  const [pagenumber, setPagenumber] = useState(0);
  const effectRan = useRef(false);

  const [localVideoStream, setLocalVideoStream] = useState(null);
  const [localVideoStreamRenderer, setLocalVideoStreamRenderer] = useState(null);
  const [riseHandActiveStatus, setRiseHandActiveStatus] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenForRecording, setIsDialogOpenForRecording] = useState(false);
  const [isRecordingModal, setIsRecordingModal] = useState(false);
  const [isParticipantShow, setIsParticipantShow] = useState(false);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [isVideoOnGoing, setIsVideoOnGoing] = useState(true);
  const [isAudioOnGoing, setIsAudioOnGoing] = useState(true);
  const [adminLink, setAdminLink] = useState('');
  const [sellerLink, setSellerLink] = useState('');
  const [isLinkValidate, setIsLinkValidate] = useState(true);
  const [participantCount, setParticipantCount] = useState(1);
  const [displayNameUi, setDisplayNameUi] = useState('');
  const [disconnectMessage, setDisconnectMessage] = useState('You have disconnected from room.');
  const [displayLobby, setDisplayLobby] = useState([]);
  const [displayParticipant, setDisplayParticipant] = useState([]);
  const [remoteVideoStreamsArr, setRemoteVideoStreamsArr] = useState([]);
  const [dynamicMessage, setDynamicMessage] = useState('');
  const localVideoContainerRef = useRef(null);
  useEffect(() => {
    if (effectRan.current) return;
    deviceManager = null;
    // Call the external function and pass the callback
    const initialize = async () => {
      //const searchParams = new URLSearchParams(window.location.search);
      const query = new URLSearchParams(window.location.search);
      acsusrt = query.get('acsusrt')
      acsRoomId = query.get('rmid')
      secretId = query.get('scrtid')

      if (acsusrt == 1) { //admin
        isCallCreator = true;
      } else if (acsusrt == 2) { //seller
        isCallCreator = false;
      } else { //buyer
        isCallCreator = false;
      }

      const { userIdServer, token } = await getAcsToken();
      userAccessToken = token;
      userId = userIdServer
      clientObj.userId = userId;
      console.log(socket.id, '----userId--', userId);

      console.log(acsusrt)//123
      console.log('----scrtid--', secretId);
      console.log('----rmid--', acsRoomId);

      if (acsusrt && acsRoomId && secretId) {
        addParticipant(acsRoomId, userId);
        setPagenumber(2);
        try {
          let uinfo = query.get('uinfo');
          if (uinfo && uinfo.includes('|')) {
            let uinfoArr = uinfo.split('|');
            progressId = uinfoArr[0];
            usertypeFromQuery = uinfoArr[1];
            userIdFromQuery = uinfoArr[2];
            displayName = uinfoArr[3];
            setDisplayNameUi(displayName)
            userIdFromQuery = uinfoArr[4];
            console.log(progressId, usertypeFromQuery, userIdFromQuery, displayName, userIdFromQuery);
          }
        } catch (error) {

        }

      } else {
        setPagenumber(1)
      }
      console.log('-----socket.id-onload---', socket.id)
    }

    clientObjectArr = [];
    clientPagingArr = [];
    receiveServerMessage(socket, upadteReceivedMessageCB);
    oneToOneMessage(socket, updateOneToOneMessageCB);
    updateUiOnAcceptCall(socket, updateUiOnAcceptCallCB);
    updateStatusOnUi(socket, updateStatusOnUiCB);

    initialize();

    effectRan.current = true;
    return () => {
    };
  }, []);

  const upadteReceivedMessageCB = ({ participantList, dataObj }) => { // receive all participant on joining
    console.log(dataObj, "---111--data----", participantList);
    //const filteredArr = participantList;// data.dataArr.filter(obj => obj.to == touser);
    if (participantList && participantList.length > 0) {
      if (dataObj.userId == userId) { // new join own 
        clientObjectArr = participantList;
      } else {
        let indexInData = clientObjectArr.findIndex(item => item.userId === dataObj.userId)
        if (indexInData > -1) {
          clientObjectArr[indexInData] = dataObj;
        } else {
          clientObjectArr.push(dataObj); // other participant update in participant list
        }

        console.log('-----sss -----', clientObjectArr)
      }

      if (isCallCreator) { // for admin
        onloadDisplayPreviewOnLoad();
        checkIfLobbyExistOrNot('initial');
      }
      console.log('--filteredArr--', clientObjectArr);

    } else {
      updateCount(0)
      setDisplayRemoteParticipant([])
      setParticipantCount(0)
    }
  };
  const updateUiOnAcceptCallCB = ({ participantList, dataObj, value, type }) => { // Accepted call back
    console.log(userId, "---updateUiOnAcceptCallCB----", participantList, dataObj, value, type);
    if (dataObj.userId === userId) { // update own data
      clientObjectArr = participantList;
      console.log("--matched----");
      handleJoinRoomCall();
    } else { // update existing data
      let index = clientObjectArr.findIndex(item => item.userId === dataObj.userId);
      console.log("--index----", index);
      if (index > -1) {
        clientObjectArr[index].isAcceptedByAdmin = true
      } else {
        clientObjectArr.push(dataObj);
      }
      if(isParticipantOpen){
        openParticipantList();
      }
      onloadDisplayPreviewOnLoad();
    }
    console.log("--after-updateUiOnAcceptCallCB----", clientObjectArr);

  };
  const updateStatusOnUiCB = ({ acsRoomId, senderUserId, socketId, data, type, typeValue }) => { //  Accepted call back
    //let { acsRoomId, userId, socketId, data, type, typeValue } = data;
    console.log(data, "---updateStatusOnUiCB11----", acsRoomId, senderUserId, socketId, data, type, typeValue);
    let index = clientObjectArr.findIndex(item => item.userId === senderUserId);
    console.log("---index----", index);
    if (index > -1) {
      if (type == 'updateRiseHand') {
        clientObjectArr[index].isRiseHand = typeValue
        modalRefParticipant.current.update(clientObjectArr);
        updateUiOnDemand(senderUserId, 'updateRiseHand', typeValue)
      } else if (type === 'updateMuteUnmute') {
        clientObjectArr[index].isMute = typeValue
        modalRefParticipant.current.update(clientObjectArr);
        updateUiOnDemand(senderUserId, 'updateMuteUnmute', typeValue)
      } else if (type === 'updateVideoOnOff') {
        clientObjectArr[index].isVideoOff = typeValue
        modalRefParticipant.current.update(clientObjectArr);
        console.log("---voff----", participantCount);
        onloadDisplayPreviewOnLoad();
      } else if (type === 'forceVideoOff') {
        clientObjectArr[index].isVideoOff = typeValue
        if (userId === senderUserId) {
          if (isVideoOnGoing) {
            const localVideoStream = call.localVideoStreams.find((stream) => { return stream.mediaStreamType === 'Video' });
            call.stopVideo(localVideoStream);
            setIsVideoOnGoing(false);
          }
        }
      } else if (type === 'forceMute') {
        clientObjectArr[index].isMute = typeValue
        if (userId === senderUserId) {
          if (isAudioOnGoing) {
            call.mute();
          }
          //alert('Audio mute functionality need implement here')
          setIsAudioOnGoing(!isAudioOnGoing);
        }
        updateUiOnDemand(senderUserId, 'updateMuteUnmute', typeValue)
      } else if (type === 'RejectLobby') {
        let indexInData = clientObjectArr.findIndex(item => item.userId === senderUserId)
        if (indexInData > -1) {
          clientObjectArr.splice(indexInData, 1) //delete from participant
        }
        if (userId === senderUserId) {
          setDynamicMessage('Your joining request has been Rejected by the admin.')
        }
        if(isParticipantOpen){
          openParticipantList();
        }
      } else if (type === 'ForceRemoved') {
        console.log("---force remove before----", clientObjectArr);
        let index = clientObjectArr.findIndex(item => item.userId === senderUserId)
        if (index > -1) {
          clientObjectArr.splice(index, 1)
        }
        
        if (userId === senderUserId) {
          console.log("---force 11----");
          setDisconnectMessage('You were forcefully removed by the admin.')
          disconnectCall(type);
        }else{
          if(isParticipantOpen){
            openParticipantList();
          }
          onloadDisplayPreviewOnLoad();
        }
      } else if (type === 'ClickOnDisconnect') {
        console.log("---force remove before----", clientObjectArr);
        let index = clientObjectArr.findIndex(item => item.userId === senderUserId)
        if (index > -1) {
          clientObjectArr.splice(index, 1)
        }
        
          if(isParticipantOpen){
            openParticipantList();
          }
          onloadDisplayPreviewOnLoad();
      }

    }
    console.log("---after status----", clientObjectArr);
  };
  const updateOneToOneMessageCB = ({ SocketId, message, value, type, userId }) => {
    console.log('-----receive one to one-----', SocketId, message, value, type, userId)
    //if (type === 'RejectLobby') {
    setDynamicMessage(message)
    alert(message);
    //}
    if (type === 'RejectLobby') {
      console.log('-----before -----', clientObjectArr)
      let indexInData = clientObjectArr.findIndex(item => item.userId === userId)
      if (indexInData > -1) {
        clientObjectArr.splice(indexInData, 1) //delete from participant
        console.log('-----dd -----')
      }
      console.log('-----after -----', clientObjectArr)
    } else if (type === 'ForceRemoved') {
      setDisconnectMessage('You were forcefully removed by the admin.')
      disconnectCall(type);
    }

  };
  const handleJoinRoomCall = async () => {
    // partiArrBeforeMyJoining = [];
    // serverCallId = ''//socket.emit('BE-addupdate-participant', { roomId: acsRoomId, userId: userId, data: tempObj, type: 'addNew', socketId: socket.id });
    //console.log('--------local pushed---', partiArrBeforeMyJoining)

    // isToastShowingFlag = false;
    setPagenumber(3)
    try {
      const callClient = new CallClient();
      const tokenCredential = new AzureCommunicationTokenCredential(userAccessToken.trim());
      callAgent = await callClient.createCallAgent(tokenCredential, { displayName: displayName + '###' + socket.id });

      const manager = await callClient.getDeviceManager();
      await manager.askDevicePermission({ video: true });
      await manager.askDevicePermission({ audio: true });
      deviceManager = manager
    } catch (error) {
      console.error('-------err-', error);
    }


    // onloadDisplayPreviewOnLoad();
    if (!deviceManager) {
      console.error('DeviceManager is not initialized.');
      return;
    }
    setTimeout(async () => {

      try {
        const localStream = await createLocalVideoStream();
        const videoOptions = localStream ? { localVideoStreams: [localStream] } : undefined;
        const roomCallLocator = { roomId: acsRoomId.trim() };
        call = callAgent.join(roomCallLocator, { videoOptions });

        subscribeToCall(call);

      } catch (error) {
        console.error(error);
      }
    }, 1000)


    // setTimeout(() => {
    //   isToastShowingFlag = true;
    // }, 5000)
    console.log('--------enter call--------')
    //alert('entry in call');
  };
  const subscribeToCall = (call) => {
    callId = call.id;
    // call.on('incomingCall', (()=>{
    //   alert('in coming call')
    // }));
    try {
      console.log(`Call Id: ${call.id}`);
      call.on('idChanged', () => console.log(`Call Id changed: ${call.id}`));
      console.log(`Call state: ${call.state}`);
      // call.on('isScreenSharingOnChanged', () => {
      //   console.log('------screen sharing-------', call.isScreenSharingOn);
      // });

      call.on('stateChanged', async () => {
        console.log(`Call state changed: ${call.state}`);
        if (call.state === 'Connected') {
          console.log('user connected to call');
        } else if (call.state === 'Disconnected') {
          console.log(`Call ended, call end reason={code=${call.callEndReason.code}, subCode=${call.callEndReason.subCode}}`);
        }
      });

      call.on('isLocalVideoStartedChanged', () => {
        console.log(`isLocalVideoStarted changed: ${call.isLocalVideoStarted}`)
      });

      call.localVideoStreams.forEach(async (lvs) => {
        setLocalVideoStream(lvs);
        await displayLocalVideoStream(lvs);
      });
      call.on('localVideoStreamsUpdated', (e) => {
        e.added.forEach(async (lvs) => {
          setLocalVideoStream(lvs);
          await displayLocalVideoStream(lvs);
        });
        e.removed.forEach(() => removeLocalVideoStream());
      });

      //call.remoteParticipants.forEach(subscribeToRemoteParticipant); // can comment
      call.on('remoteParticipantsUpdated', (e) => {
        // try {
        //   call.remoteParticipants.forEach(participant => {
        //     // console.log(participant.videoStreams.length, '-----participant in1 ----', participant.length)
        //     participant.videoStreams.forEach(videoStream => {
        //       let participantId = participant.identifier.communicationUserId;
        //       if (videoStream.isAvailable && !isVideoAvailablePartiArr.includes(participantId)) {
        //         isVideoAvailablePartiArr.push(participantId);
        //       }
        //     });
        //   });
        // } catch (error) {

        // }
        const participants = call.remoteParticipants;
        const participantCountLocal = (participants.length) + 1; // 
        participantCountGlobal = participantCountLocal;
        console.log(participants.length,'---------participantCountLocal--------' + participantCountLocal);
        setParticipantCount(participantCountLocal)
        if ((participantCountGlobal == 2 || participantCountGlobal == 3) && !isVideoOnGoing)
          removeLocalVideoStream() //to update local video stream

        // //socket.emit('BE-get-updatedParticipantList', { roomId: acsRoomId, SocketId: socket.id });
        // socket.emit('BE-update-participantcount', { roomId: acsRoomId, count: participantCountLocal, SocketId: socket.id });

        // // e.added.forEach(subscribeToRemoteParticipant);
        e.added.forEach((participant) => {
          isParticipantRemoved = false;
          subscribeToRemoteParticipant(participant)
          //const participantId = getParticipantId(participant);
        });
        e.removed.forEach((participant) => {
          isParticipantRemoved = true;
          console.log('---12--Remote participant removed from the call.',participant);
          //let participantId = participant.identifier.communicationUserId;
          //socket.emit('BE-sendmessage-to-oneuser', {acsRoomId:acsRoomId,userId:participantId, SocketId: null, message: 'delete from server', value: '', type:'participantRemoveFromCall' });
          subscribeToRemoteParticipant(participant)
          // removeParticipantView(participant);
          // const participantId = getParticipantId(participant);
          // socket.emit('BE-addupdate-participant', { roomId: acsRoomId, userId: participantId, data: null, type: 'removeFromCall', socketId: socket.id });
        });


      });


    } catch (error) {
      console.error(error);
    }
  };
  const getParticipantId = (participant) => {
    const identifier = participant.identifier;
    if ('communicationUserId' in identifier) {
      return identifier.communicationUserId;
    } else if ('phoneNumber' in identifier) {
      return identifier.phoneNumber;
    } else if ('microsoftTeamsUserId' in identifier) {
      return identifier.microsoftTeamsUserId;
    } else {
      return 'Unknown';
    }
  };
  // const removeParticipantView = (participant) => {
  //   const participantId = getParticipantId(participant);
  //   const remoteVideoContainer = document.getElementById(`participant-${participantId}`);
  //   if (remoteVideoContainer && remoteVideosGalleryRef.current.contains(remoteVideoContainer)) {
  //     remoteVideosGalleryRef.current.removeChild(remoteVideoContainer);
  //   }
  // };
  const subscribeToRemoteParticipant = (remoteParticipant) => {
    let participantId = remoteParticipant.identifier.communicationUserId;
    let splitArr = remoteParticipant.displayName.split('###')
    let disName = splitArr[0];
    let socketId = splitArr[1];
    try {
      //console.log('---display name1---', remoteParticipant.displayName);
      console.log('Remote participant state:', remoteParticipant);
      remoteParticipant.on('stateChanged', () => {
        console.log(`Remote participant state changed: ${remoteParticipant.state}`)
      });

      console.log(disName, participantId, '------before---clientObjectArr----', clientObjectArr)
      //let tempArr = []
      if (isParticipantRemoved) {
        let index = clientObjectArr.findIndex(item => item.userId === participantId)
        if (index > -1) {
          clientObjectArr.splice(index, 1);
        }
      } else {
        remoteParticipant.videoStreams.forEach(stream => {
          if (stream.isAvailable) {
            let index = clientObjectArr.findIndex(item => item.userId === participantId)
            if (index > -1) {
              clientObjectArr[index].stream = stream;
            }
          }

        });
      }

      console.log(disName, participantId, '------after---clientObjectArr----', clientObjectArr)
      onloadDisplayPreviewOnLoad()
      //console.log('---after-----------',clientPagingArr);
      //setDisplayRemoteParticipant(clientPagingArr)
      //setDisplayParticipant([...partiArrBeforeMyJoining]) // update the participant list

      //console.log('------after set-------', remoteVideoForOnePageArr)

      // remoteParticipant.on('videoStreamsUpdated', (e) => {
      //   e.added.forEach(stream => subscribeToRemoteVideoStream(stream, remoteParticipant, disName));
      //   e.removed.forEach(() => {
      //     console.log('-2-Remote participant video stream was removed.')
      //   });
      // });
      // remoteParticipant?.audioStreams?.forEach(stream => subscribeToRemoteAudioStream(stream, remoteParticipant));
      // remoteParticipant.on('audioStreamsUpdated', (e) => {
      //   e.added.forEach(stream => subscribeToRemoteAudioStream(stream, remoteParticipant));
      //   e.removed.forEach(() => console.log('Remote participant audio stream was removed.'));
      // });

      // Initial mute status
      //updateMuteStatus(remoteParticipant);

    } catch (error) {
      console.error(error);
    }


    // if (isParticipantRemoved) {
    //   let index = partiArrBeforeMyJoining.findIndex(item => item.userId === participantId)
    //   if (index > -1) {
    //     partiArrBeforeMyJoining.splice(index, 1);
    //     setDisplayParticipant(partiArrBeforeMyJoining);
    //   }
    //   if (isToastShowingFlag)
    //     toast(disName + " left the call");
    // } else {
    //   if (isToastShowingFlag)
    //     toast(disName + " Added to call");
    // }


  };

  const createLocalVideoStream = async () => {
    if (!deviceManager) {
      console.error('DeviceManager is not initialized.');
      return;
    }

    const camera = (await deviceManager.getCameras())[0];
    if (camera) {
      return new LocalVideoStream(camera);
    } else {
      console.error(`No camera device found on the system`);
    }
  };
  const displayLocalVideoStream = async (localVideoStream) => {
    try {

      const renderer = new VideoStreamRenderer(localVideoStream);
      const view = await renderer.createView();
      setLocalVideoStreamRenderer(renderer);
      // Clear the container before appending new elements
      if (localVideoContainerRef.current) {
        localVideoContainerRef.current.innerHTML = '';
      }
      // Add the mute status element
      const localContainer = document.createElement('div');
      //localContainer.className = 'local-video-container-base';

      localContainer.appendChild(view.target);
      localVideoContainerRef.current.hidden = false;
      localVideoContainerRef.current.appendChild(localContainer);

      // Add the short name element
      const shortNameElement = document.createElement('div');
      shortNameElement.className = 'short-name';
      shortNameElement.innerText = 'You';
      localContainer.appendChild(shortNameElement);

      // Add the mute status element
      const muteStatusElement = document.createElement('img');
      muteStatusElement.className = 'mute-status';
      muteStatusElement.src = isAudioOnGoing ? unmute_incall : mute_incall;
      muteStatusElement.id = `mute-status-${userId}`;
      localContainer.appendChild(muteStatusElement);

      // Add the rise hand status element
      const risehandStatusElement = document.createElement('img');
      risehandStatusElement.className = 'risehand-status';
      risehandStatusElement.src = raise_hand_active;
      risehandStatusElement.style.display = 'none';
      risehandStatusElement.id = `risehand-status-${userId}`;
      localContainer.appendChild(risehandStatusElement);

    } catch (error) {
      console.error(error);
    }
  };
  const getClassName = () => {
    return participantCountGlobal < 3 && !isRightPanelOpen ? "local-video-container-custom" : participantCountGlobal < 3 && isRightPanelOpen ? "local-video-container-withmenu-custom" : participantCountGlobal == 3 ? "local-video-three-custom" : participantCountGlobal == 4 ? "local-video-four-custom" : participantCountGlobal < 7 ?"local-video-morethan-four-custom" : "local-video-morethan-six-custom"
  }
  const removeLocalVideoStream = () => {
    console.log(participantCount, '---remove--1----', isAudioOnGoing, participantCountGlobal)
    try {
      //localVideoStreamRenderer.dispose();
      // Clear the container and add the short name with background color
      if (localVideoContainerRef.current) {
        localVideoContainerRef.current.innerHTML = '';
        const shortNameContainer = document.createElement('div');
        // shortNameContainer.className = participantCountGlobal < 3 ? 'lvideo-offcontainer-lessthanthree' : 'short-name-container';
        shortNameContainer.className = getClassName();
        //shortNameContainer.style.backgroundColor='background: #FF8080'
        localVideoContainerRef.current.appendChild(shortNameContainer);
        // Add the short name element
        const shortNameElement = document.createElement('div');
        shortNameElement.className = 'short-name';
        shortNameElement.innerText = 'You';
        shortNameContainer.appendChild(shortNameElement);

        //shortNameContainer.textContent = 'You' + participantCount; // Replace with actual short name


        const muteStatusElement = document.createElement('img');
        muteStatusElement.className = 'mute-status';
        muteStatusElement.src = isAudioOnGoing ? unmute_incall : mute_incall;
        muteStatusElement.id = `mute-status-${userId}`;
        shortNameContainer.appendChild(muteStatusElement);

        localVideoContainerRef.current.hidden = false;
      }
    } catch (error) {
      console.error(error);
    }

  };
  const displayLobbyOnLoad = () => {

  }
  const onloadDisplayPreviewOnLoad = () => {
    if (pageIndex == 0) {
      const filteredArr = clientObjectArr.filter(obj => obj.isAcceptedByAdmin == true && obj.userId != userId);
      console.log(participantCount,'------after filter---clientObjectArr----', filteredArr)
      if (filteredArr && filteredArr.length > 0) {
        if (filteredArr.length < pageSize) {
          let count=filteredArr.length + 1
          console.log('------11count--------',count)
          setParticipantCount(count)
          participantCountGlobal=count;
          clientPagingArr = filteredArr;
        } else {
          console.log('------bb11count--------',pageSize + 1)
          setParticipantCount(pageSize + 1)
          participantCountGlobal=pageSize + 1
          clientPagingArr = filteredArr.slice(0, pageSize);
        }
        updateCount(participantCountGlobal)
        setTimeout(()=>{
          
          setDisplayRemoteParticipant([...clientPagingArr])
        },5000)

        console.log('------updated ui----', filteredArr)
      } else {
        participantCountGlobal=0
        setParticipantCount(0)
        updateCount(0)
        setDisplayRemoteParticipant([])
      }

    } else {
      changePage();
    }
  }
  const changePage = () => {
    if (pageIndex > 0) {
      pageSize = 9
    } else {
      pageSize = 8
    }
    console.log(pageIndex, ' --paggiStartIndex- ', paggiStartIndex, '-----paggiLastIndex--------', paggiLastIndex)
    clientPagingArr = [];
    try {
      for (let i = paggiStartIndex; i < paggiLastIndex; i++) {
        if (clientObjectArr[i].displayName)
          clientPagingArr.push(clientObjectArr[i])
      }
    } catch (error) {

    }
    participantCountGlobal=clientPagingArr.length + 1
    setParticipantCount(clientPagingArr.length + 1)
    updateCount(participantCountGlobal)
    setDisplayRemoteParticipant([...clientPagingArr]);
  }
  function getInitials(str) {
    try {
      let initials = ''
      if (!str.includes(' ')) {
        initials = str.slice(0, 2).toUpperCase()
      } else {
        const words = str.split(' ');
        initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        if (initials.length > 2) {
          initials = initials.slice(0, 2)
        }
      }
      return initials;
    } catch (error) {
      return str
    }
  }
  //count == 5 ? "video-part-five" :
  const checkIfLobbyExistOrNot = (eventType) => {
    if (isCallCreator) {
      let tempArr = clientObjectArr.filter(obj => obj.isAcceptedByAdmin == false);
      if (tempArr && tempArr.length > 0) {
        setDisplayLobby([...tempArr]);
        setIsRightPanelOpen(true)
        modalRefForLobby.current.open();
        modalRefForLobby.current.update(tempArr);
      } else {
        setDisplayLobby([]);
        if (eventType === 'onclick'){ //modal should open if click on participant.
          modalRefForLobby.current.open();
          modalRefForLobby.current.update([]);
        } 

      }
    }

    
      

    modalRefParticipant.current.close();


    // modalRefParticipant.current.close();
    // modalRefForLobby.current.open();
    //modalRefParticipant.current.close();
  }

  const CbFromParticipant = (data) => {
    setIsRightPanelOpen(data.isOpen)
    if (data.from === 'lobby') {
      isLobbyOpen = data.isOpen;
    } else {
      isParticipantOpen = data.isOpen;
    }
  }
  const getLinkAfterCreateRoom = (data) => {
    acsRoomId = data.acsRoomId
    setAdminLink(data.adminlink)
    setSellerLink(data.sellerLink)
  }
  const startCallOnClick = () => {
    // if (!isLinkValidate) {
    //   alert('invalid link')
    // } else if (!displayNameUi) {
    //   alert('Please enter display name')
    // } else {
    //   if (isCallCreator) {
    //     registerEventForDialogForAdmin()
    //     handleJoinRoomCall()
    //   } else {
    //     socket.emit('BE-join-room', { acsRoomId, userName: userId, joinerSocketId: socket.id, displayName: displayName }); //click on join call button
    //   }
    // }
    if (!displayNameUi) {
      alert('Please enter display name')
    } else {
      if (isCallCreator) {
        const tempObj = { userId: userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: socket.id, displayName: displayName, isRiseHand: false, isMute: false, isVideoOff: false, isAllowRecording: false, stream: null, isAllowCamera: true, isAllowMicroPhone: true, isAcceptedByAdmin: isCallCreator ? true : false };
        socket.emit('BE-join-room', { acsRoomId, userName: userId, socketId: socket.id, data: tempObj });
        // registerEventForDialogForAdmin()
        handleJoinRoomCall()
      } else {
        setDynamicMessage('You are waiting in lobby. please wait for Admins acceptance')
        const tempObj = { userId: userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: socket.id, displayName: displayName, isRiseHand: false, isMute: false, isVideoOff: false, isAllowRecording: false, stream: null, isAllowCamera: true, isAllowMicroPhone: true, isAcceptedByAdmin: isCallCreator ? true : false };
        socket.emit('BE-join-room', { acsRoomId, userName: userId, socketId: socket.id, data: tempObj }); //click on join call button


        //socket.emit('BE-addupdate', { roomId: acsRoomId, userId: userId, type: 'addNew', data: tempObj, socketId: socket.id });
      }
      console.log('-----socket.id----', socket.id)

    }
  }
  const startVideo = async () => {
    const cameras = await deviceManager.getCameras();
    const camera = cameras[0]
    const localVideoStream = new LocalVideoStream(camera);
    await call.startVideo(localVideoStream);
  };
  const updateMuteStatusLocal = () => {
    const muteStatusElement = document.getElementById(`mute-status-${userId}`);
    if (muteStatusElement) {
      muteStatusElement.src = isAudioOnGoing ? mute_incall : unmute_incall;
    }
  };
  const updateRiseHandStatusLocal = (status) => {
    const risehandStatusElement = document.getElementById(`risehand-status-${userId}`);
    if (risehandStatusElement) {
      if (status)
        risehandStatusElement.style.display = 'block';
      else
        risehandStatusElement.style.display = 'none';
    }
  };
  const disconnectCall = (type) => {
    isToastShowingFlag = false;
    call.hangUp();
    setPagenumber(4);
    setTimeout(() => {
      isToastShowingFlag = true;
    }, 5000)
  }
  const openParticipantList = () => {
    modalRefForLobby.current.close();
    let tempArr = clientObjectArr.filter(obj => obj.isAcceptedByAdmin == true);
    modalRefParticipant.current.open(tempArr);
    modalRefParticipant.current.update(tempArr);
  }

  return (
    <div className="App">
      <ToastContainer />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', }}>
        {pagenumber == 0 ? <div className="loading-spinner">
          <div className="spinner"></div>
        </div> :
          pagenumber == 1 ?
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginTop: 100, marginLeft: 70 }}>
              {/* -------------page 1---------- */}
              <text style={{ fontSize: 50, fontWeight: 600, }}>Video calls and meetings for everyone test</text>
              <text style={{ fontSize: 20, fontWeight: 400, color: 'gray', marginTop: 15 }}>Connect, collaborate, and celebrate from anywhere </text>
              <img src={newmeeting} alt="Description" onClick={() => {
                createRoom(userId, getLinkAfterCreateRoom)
              }}
                style={{ cursor: 'pointer', height: 60, width: 150, resizeMode: 'contain', marginTop: 30 }} />


              {adminLink ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>Admin :  {adminLink}</p>
                <p>User :  {sellerLink}</p>
                <img src={nextbtn} alt="Description" onClick={() => {
                  //setPagenumber(2)
                  window.open(adminLink, "_blank", "noreferrer");
                }}
                  style={{ cursor: 'pointer', height: 40, width: 100, resizeMode: 'contain', marginTop: 30, borderRadius: 10 }} />
              </div> : null
              }
            </div> :
            pagenumber == 2 ? <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
              {/* -------------page 2---------- */}
              {!isCallCreator ? <p style={{ fontSize: 20, position: 'absolute', left: 500, top: 100, color: 'red', zIndex: 999 }}>{dynamicMessage}</p> : null}
              <p className="txtstyle">Display Name</p>
              <input className="inputstyle"
                type="text"
                placeholder="Enter display name"
                value={displayNameUi}
                onChange={(e) => {
                  displayName = e.target.value
                  clientObj.displayName = e.target.value;
                  setDisplayNameUi(e.target.value)
                }}
              />
              {/* <img src={startcall} alt="Description" style={{ height: 320, resizeMode: 'contain' }} /> */}
              <p className="txtstyleStartCall">Start a call</p>
              {/* <div className='startcall-box'>
                <p className="txtstyleCameraoffon">Your camera is turned off</p>
                <div className='cameraBox' >
                  <img src={mic} alt="Description" style={{ height: 40, resizeMode: 'contain', marginRight: 10 }} />
                  <img src={camera} alt="Description" style={{ height: 40, resizeMode: 'contain', marginLeft: 10 }} />
                </div>

              </div> */}


              <img src={isCallCreator ? callbtn : join_call} alt="Description" onClick={() => {
                startCallOnClick();
              }}
                style={{ width: 140, resizeMode: 'contain', cursor: 'pointer', }} />

            </div> : pagenumber == 3 ?
              <div className="maindiv-calling">
                <div className="videopart-main-div">
                  <div className="video-under-main-div">
                    <div className={participantCount < 4 ? "video-part-lessthan-four" : participantCount == 4 ? "video-part-four" : "video-part-morethan-four"} >



                      {displayRemoteParticipant && displayRemoteParticipant.map((item, index) => (
                        <RenderDynamicView key={item.userId} remoteVideoStream={item.stream} item={item} participantCount={participantCountGlobal} pageIndex={pageIndex} />
                      ))}
                      {pageIndex > 0 ? null : <div className={participantCount < 3 && !isRightPanelOpen ? "local-video-container" : participantCount < 3 && isRightPanelOpen ? "local-video-container-withmenu" : participantCount == 3 ? "local-video-three" : participantCount == 4 ? "local-video-four" : participantCount < 7 ?"local-video-morethan-four":"local-video-morethan-six"} ref={localVideoContainerRef}></div>}
                      {/* {count == 2 && pageIndex == 0 ? <div className={"local-two"}>
                        {displayNameUi}
                      </div> : null} */}
                    </div>
                  </div>
                  {/* -----------------lobby List------------------------ */}
                  <DisplayLobbyComponent ref={modalRefForLobby} acsRoomId={acsRoomId} userId={userId} displayLobby={displayLobby} socket={socket} CbFromParticipant={CbFromParticipant} />
                  {/* -----------------participant List------------------------ */}
                  <DisplayParticipantComponent ref={modalRefParticipant} acsRoomId={acsRoomId} userId={userId} socket={socket} isCallCreator={isCallCreator} CbFromParticipant={CbFromParticipant} />


                </div>
                {/* -------------page 3---------- */}


                <div className="video-control-part">
                  {/* ----------Video--------- */}
                  <img src={isVideoOnGoing ? start_video : stop_video} alt="Description" onClick={() => {
                    const tempObj = { userId: userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: socket.id, displayName: displayName, isRiseHand: isVideoOnGoing };
                    socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: userId, socketId: socket.id, data: tempObj, type: 'updateVideoOnOff', typeValue: isVideoOnGoing });


                    if (isVideoOnGoing) {
                      const localVideoStream = call.localVideoStreams.find((stream) => { return stream.mediaStreamType === 'Video' });
                      call.stopVideo(localVideoStream);
                    } else {
                      startVideo()
                    }
                    setIsVideoOnGoing(!isVideoOnGoing);
                  }}
                    style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', }} />
                  {/* ----------Audio--------- */}
                  <img src={isAudioOnGoing ? unmute : mute} alt="Description" onClick={() => {
                    const tempObj = { userId: userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: socket.id, displayName: displayName, isRiseHand: isAudioOnGoing };
                    socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: userId, socketId: socket.id, data: tempObj, type: 'updateMuteUnmute', typeValue: isAudioOnGoing });

                    if (isAudioOnGoing) {
                      call.mute();
                    } else {
                      call.unmute();
                    }
                    // socket.emit('BE-broadcast-message', { acsRoomId, userId: userId, socketId: socket.id, displayName: displayName, actionType: 'muteUnmute', actionValue: isAudioOnGoing });
                    setIsAudioOnGoing(!isAudioOnGoing);
                    console.log('---isAudioOnGoing------', isAudioOnGoing)
                    updateMuteStatusLocal()
                  }}
                    style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} />
                  {/* ----------end call--------- */}
                  <img src={end_call} alt="Description" onClick={() => {
                    socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: userId, socketId: socket.id, data: null, type: 'ClickOnDisconnect', typeValue: true });
                    setDisconnectMessage('You have disconnected from room.')
                    disconnectCall('onclick')
                    // try {
                    //   setDisconnectMessage('You have disconnected from room.')
                    //   disconnectCall('onclick')

                    // } catch (error) {
                    //   console.log('---end call--', error);
                    // }
                  }}
                    style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} />
                  {/* ----------participant--------- */}
                  <img src={participant} alt="Description" onClick={() => {
                    openParticipantList()
                    //modalRefForLobby.current.close();
                    //checkIfLobbyExistOrNot('onclick');

                    // if (isParticipantShow) {
                    //   setIsParticipantShow(false)
                    //   if (isCallCreator)
                    //     setIsDialogOpen(true)
                    // } else {
                    //   setDisplayParticipant([...partiArrBeforeMyJoining])
                    //   setIsParticipantShow(true)
                    //   setIsDialogOpen(false)
                    // }
                  }}
                    style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} />
                  {/* ----------lobby--------- */}
                  {
                    isCallCreator ? <img src={lobby} alt="Description" onClick={() => {
                      checkIfLobbyExistOrNot('onclick');
                      // if (isParticipantShow) {
                      //   setIsParticipantShow(false)
                      //   if (isCallCreator)
                      //     setIsDialogOpen(true)
                      // } else {
                      //   setDisplayParticipant([...partiArrBeforeMyJoining])
                      //   setIsParticipantShow(true)
                      //   setIsDialogOpen(false)
                      // }
                    }}
                      style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} /> : null}
                  {/* ----------rise hand--------- */}
                  <img src={riseHandActiveStatus ? raise_hand_active : rise_hand} alt="Description" onClick={() => {
                    const tempObj = { userId: userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: socket.id, displayName: displayName, isRiseHand: !riseHandActiveStatus };
                    socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: userId, socketId: socket.id, data: tempObj, type: 'updateRiseHand', typeValue: !riseHandActiveStatus });
                    updateRiseHandStatusLocal(!riseHandActiveStatus)
                    setRiseHandActiveStatus(!riseHandActiveStatus);
                    // const raiseHandFeature = call.feature(Features.RaiseHand);
                    // raiseHandFeature.raiseHand();

                  }}
                    style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} />
                  {/* ----------call recording--------- */}
                  {
                    isCallCreator ? <img src={recording_start} alt="Description" onClick={() => {
                      alert(participantCount)
                      // if (partiArrBeforeMyJoining && partiArrBeforeMyJoining.length > 0) {
                      //   for (let i = 0; i < partiArrBeforeMyJoining.length; i++) {
                      //     partiArrBeforeMyJoining[i].isAllowRecording = false
                      //   }
                      //   setDisplayParticipant([...partiArrBeforeMyJoining]);
                      // }
                      // setIsDialogOpenForRecording(true)
                      // socket.emit('BE-broadcast-message', { acsRoomId, userId: userId, socketId: socket.id, displayName: displayName, actionType: 'adminWantsToRecord', actionValue: true });
                    }}
                      style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} /> : null
                  }
                  {/* ----------prev--------- */}
                  <img src={prev} alt="Description" onClick={() => {
                    if (pageIndex > 0) {
                      pageIndex--
                      paggiStartIndex = pageIndex * pageSize;
                      paggiLastIndex = pageIndex === 0 ? pageSize : paggiStartIndex + pageSize;
                      changePage()
                    } else {
                      toast('You have in first index');
                    }

                  }}
                    style={{ height: 40, width: 40, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} />
                  {/* ----------next--------- */}
                  <img src={next} alt="Description" onClick={() => {
                    let pSize = parseInt((clientObjectArr.length) / pageSize);
                    console.log('onclick --', clientObjectArr.length, pSize)
                    if (pageIndex < pSize) {
                      pageIndex++
                      console.log('inside if --', pageIndex, pageSize)
                      paggiStartIndex = pageIndex * pageSize;
                      paggiLastIndex = paggiStartIndex + pageSize;

                      changePage()
                    } else {
                      toast('You have reached at end');
                    }
                  }}
                    style={{ height: 25, width: 25, resizeMode: 'contain', cursor: 'pointer', marginLeft: 15 }} />




                </div>

              </div> :

              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
                <h1 style={{ marginTop: '7%' }}>{disconnectMessage}</h1>
                <h4>Do you want to rejoin the call?</h4>
                <img src={rejoin} alt="Description" onClick={() => {
                  setPagenumber(2)
                  setDynamicMessage('')
                  // startCallOnClick();
                  // setIsDialogOpen(false)
                  // setIsParticipantShow(false)
                  // setTimeout(() => {
                  //   if (isCallCreator) {
                  //     handleJoinRoomCall()
                  //   } else {
                  //     socket.emit('BE-join-room', { acsRoomId, userName: userId, joinerSocketId: socket.id, displayName: displayName }); //click on join call button
                  //   }
                  // }, 2000)
                }}
                  style={{ width: 140, resizeMode: 'contain', cursor: 'pointer', marginTop: 20 }} />
              </div>




        }
      </div>



























    </div>
  );
}

export default App;
