// import React from 'react';
// import { useState, useEffect } from 'react';

import io from 'socket.io-client';
const socket = io('http://localhost:5001');


export function receiveServerMessage(socket,callback) {
  socket.on('FE-getdata-from-server', ({participantList,dataObj }) => {
    //console.log(dataObj,'-----data120-----', participantList);
    callback({participantList,dataObj});
  });
  
}
export function oneToOneMessage(socket,callback) {
  socket.on('FE-getmessage-from-oneuser', ({SocketId, message ,value,type,userId}) => {
    //console.log(dataObj,'-----data120-----', participantList);
    callback({SocketId, message,value,type,userId});
  });
  
}
export function updateUiOnAcceptCall(socket,callback) {
  socket.on('FE-accept-user', ({participantList , dataObj ,value , type }) => {
    //console.log(dataObj,'-----data120-----', participantList);
    callback({participantList , dataObj ,value , type});
  });
  
}
export function updateStatusOnUi(socket,callback) {
  socket.on('FE-update-status', ({acsRoomId, senderUserId, socketId, data, type, typeValue}) => {
    console.log(data,'-----data1203-----', senderUserId);
    callback({acsRoomId, senderUserId, socketId, data, type, typeValue});
  });
  
}