import React, { forwardRef, useImperativeHandle, useState } from 'react';
import '../App.css';
import cross from '../assets/cross.png';

let touser = '';

let senderSocketIdGlobal = null, senderUserIdGlobal = null;
let isCallCreator = false, isToastShowingFlag = false;
let userAccessToken, userId, acsRoomId = '', acsusrt, callAgent, deviceManager = null, call, secretId = '', displayName = '';
let stopTwiceJoinCall = true, isParticipantRemoved = false, participantCountGlobal = 1;
let lobbyBackup = [], colorArr = ['#4BBFF1', '#A84BF1', '#F1694B'], isVideoAvailablePartiArr = [];

let clientObjectArr = [], clientPagingArr = [];
let pageIndex = 0, pageSize = 9;
let paggiStartIndex = 0, paggiLastIndex = pageSize;

let clientObj = {
  from: '', to: '', msg: '', status: '', count: 0
}
const DisplayLobbyComponent = forwardRef(({ acsRoomId, userId, displayLobby, socket,CbFromParticipant }, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [displayNameUi, setDisplayNameUi] = useState('');
  const [displayLobbyArr, setDisplayLobbyArr] = useState([]);
  //console.log('---on--', displayLobby)
  function getInitials(str) {
    try {
      let initials = ''
      if (!str.includes(' ')) {
        initials = str.slice(0, 2).toUpperCase()
      } else {
        const words = str.split(' ');
        initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        if (initials.length > 2) {
          initials = initials.slice(0, 2)
        }
      }
      return initials;
    } catch (error) {
      return str
    }
  }
  useImperativeHandle(ref, () => ({
    open: (res) => {
      //console.log('---res--',res)
      //setDisplayLobbyArr([...res])
      setIsVisible(true);
      CbFromParticipant({isOpen:true, from:'lobby'})
    },
    update: (partiArr) => {
      //alert('update')
      console.log('-----partiArr-update----', partiArr)
      setDisplayLobbyArr([...partiArr])
      //setIsVisible(true);
    },
    close: () => {
      CbFromParticipant({isOpen:false, from:'lobby'})
      setIsVisible(false);
    }
  }));

  if (!isVisible) return null;

  return (
    <div style={{ height: '60vh', display: 'flex', flex: 1, flexDirection: 'column', margin: 10, borderRadius: 14, backgroundColor: '#FFF', paddingLeft: 10, paddingRight: 10 }}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <div className='header-style'onClick={()=>{
      }}>Add People</div>
      <img src={cross} onClick={() => {
      CbFromParticipant({isOpen:false, from:'lobby'})
         setIsVisible(false)
          }} style={{ height: 28, width: 28, resizeMode: 'contain', cursor: 'pointer', marginTop: 10 }} />
    </div>
    <div className='waiting-style'>Waiting to join</div>
    <div className='lobby-div'>
      {displayLobbyArr && displayLobbyArr.length > 0 ? displayLobbyArr.map((user, index) => (
        <div style={{ display: 'flex', flexDirection: 'column', }}>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ height: 30, width: 30, borderRadius: 20, backgroundColor: colorArr[index % 3], color: '#FFF', fontSize: 14, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{getInitials(user.displayName)}</div>
              <div className='list-txt-style'>{user.displayName}</div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
              <div onClick={() => {
                let tempArr=[...displayLobbyArr]
                tempArr.splice(index, 1)
                if (!tempArr || tempArr.length == 0) {
                  setIsVisible(false)
                }
                console.log('------tempArr------',tempArr)
                setDisplayLobbyArr(tempArr)
                socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: user.userId, socketId: user.socketId, data: null, type: 'RejectLobby', typeValue: true });
                //socket.emit('BE-sendmessage-to-oneuser', {acsRoomId:acsRoomId,userId:user.userId, SocketId: user.socketId, message: 'Your request has been rejected by the admin.', value: '', type:'RejectLobby' });
              //   let indexInData = clientObjectArr.findIndex(item => item.userId === userId)
              //   if (indexInData > -1) {
              //     clientObjectArr.splice(indexInData, 1) //delete from participant
              //     console.log('-----dd -----')
              // }
              }} className='txt-deny'>Deny</div>

              <div onClick={() => {
                socket.emit('BE-admit-room', {acsRoomId:acsRoomId,userId:user.userId, SocketId: user.socketId, dataObj :user, value: '', type:'AcceptLobby' });
                let tempArr=[...displayLobbyArr]
                tempArr.splice(index, 1)
                if (tempArr.length == 0) {
                  setIsVisible(false)
                }
                setDisplayLobbyArr(tempArr)

              }} className='txt-admit'>Admit</div>

            </div>


          </div>

          {
            index < displayLobbyArr.length - 1 ? <hr className='hrstyle'></hr> : null
          }
        </div>

      )) : <p>No participant in lobby</p>}

    </div>

    {/* ----host name div------- */}
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ height: 30, width: 30, borderRadius: 20, backgroundColor: '#F1694B', color: '#FFF', fontSize: 14, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{getInitials(displayNameUi)}</div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className='list-txt-style-host'>{displayNameUi}</div>
        <div className='host-meeting-style'>Meeting Host</div>
      </div>
    </div>

  </div>
  );
});

export default DisplayLobbyComponent;
