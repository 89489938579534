import React, { forwardRef, useImperativeHandle, useState } from 'react';
import '../App.css';
import risehand_parti from '../assets/risehand_parti.png';
import mic_on_parti from '../assets/mic_on_parti.png';
import video_on_parti from '../assets/video_on_parti.png';
import video_off from '../assets/video_off.png';
import video_off_disable from '../assets/video_off_disable.png';
import mic_disable from '../assets/mic_disable.png';
import remove from '../assets/remove.png';
import mic_parti from '../assets/mic_parti.png';
import cross from '../assets/cross.png';

let colorArr = ['#4BBFF1', '#A84BF1', '#F1694B'];


const DisplayParticipantComponent = forwardRef(({ acsRoomId, userId, socket, isCallCreator,CbFromParticipant }, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [displayParticipantArr, setDisplayParticipantArr] = useState([]);
  function getInitials(str) {
    try {
      let initials = ''
      if (!str.includes(' ')) {
        initials = str.slice(0, 2).toUpperCase()
      } else {
        const words = str.split(' ');
        initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        if (initials.length > 2) {
          initials = initials.slice(0, 2)
        }
      }
      return initials;
    } catch (error) {
      return str
    }
  }
  useImperativeHandle(ref, () => ({
    open: (partiArr) => {
      //alert('lkl')
      CbFromParticipant({isOpen:true, from:'participant'})
      console.log('-----partiArr---open--', partiArr)
      //setDisplayParticipantArr(partiArr);
      setIsVisible(true);
    },
    update: (partiArr) => {
      //alert('update')
      console.log('-----partiArr-update----', partiArr)
      setDisplayParticipantArr([...partiArr]);
      //setIsVisible(true);
    },
    close: () => {
      CbFromParticipant({isOpen:false, from:'participant'})
      setIsVisible(false);
    }
  }));

  if (!isVisible) return null;

  return (
    <div style={{ height: '85vh', display: 'flex', flex: 1, flexDirection: 'column', margin: 10, borderRadius: 14, backgroundColor: '#FFF', paddingLeft: 10, paddingRight: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className='header-style'>Participant List</div>
        <img src={cross} onClick={() => { 
          CbFromParticipant({isOpen:false, from:'participant'})
          setIsVisible(false) 
        }} style={{ height: 28, width: 28, resizeMode: 'contain', cursor: 'pointer', marginTop: 10 }} />
      </div>
      <div className='lobby-div'>
        {displayParticipantArr.map((user, index) => (
          <div style={{ display: 'flex', flexDirection: 'column', }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ height: 30, width: 30, borderRadius: 20, backgroundColor: colorArr[index % 3], color: '#FFF', fontSize: 14, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{getInitials(user.displayName)}</div>
                <div className='list-txt-style'>{user.userId == userId ? 'You' : user.displayName}</div>
              </div>
              {user.isRiseHand ? <img src={risehand_parti} style={{ height: 20, width: 20, resizeMode: 'contain', marginLeft: 7 }} /> : null}
              {
                isCallCreator ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>

                  <img src={user.isVideoOff ? video_off_disable : video_on_parti} onClick={() => {
                    let tempArr = [...displayParticipantArr];
                    if (isCallCreator && !tempArr[index].isVideoOff) {
                      tempArr[index].isVideoOff = true;
                      const tempObj = { userId: user.userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: user.socketId, displayName: user.displayName, isRiseHand: user.isRiseHand };
                      socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: user.userId, socketId: user.socketId, data: tempObj, type: 'forceVideoOff', typeValue: true });
                      setDisplayParticipantArr(tempArr)
                    }

                  }} style={{ height: 20, width: 20, resizeMode: 'contain', cursor: isCallCreator ? 'pointer' : 'default', marginLeft: 7 }} />

                  <img src={user.isMute ? mic_disable : mic_on_parti} onClick={() => {
                    let tempArr = [...displayParticipantArr];
                    if (isCallCreator && !tempArr[index].isMute) {
                      tempArr[index].isMute = true;
                      const tempObj = { userId: user.userId, userType: isCallCreator ? 'Admin' : 'Other', socketId: user.socketId, displayName: user.displayName, isRiseHand: user.isRiseHand };
                      socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: user.userId, socketId: user.socketId, data: tempObj, type: 'forceMute', typeValue: true });
                      setDisplayParticipantArr(tempArr)
                    }

                    // let tempArr = [...displayParticipantArr];
                    // if (isCallCreator && !tempArr[index].isMute) {
                    //   tempArr[index].isMute = true;
                    //   socket.emit('BE-broadcast-message', { acsRoomId, userId: user.userId, socketId: user.socketId, displayName: user.displayName, actionType: 'muteUnmute', actionValue: true });
                    //   const muteStatusElement = document.getElementById(`mute-status-${user.userId}`);
                    //   if (muteStatusElement) {
                    //     muteStatusElement.src = mute_incall;
                    //   }
                    // }

                  }} style={{ height: 20, width: 20, resizeMode: 'contain', cursor: isCallCreator ? 'pointer' : 'default', marginLeft: 7 }} />

                  {isCallCreator ? <img src={remove} onClick={() => {
                    socket.emit('BE-update-status', { acsRoomId: acsRoomId, senderUserId: user.userId, socketId: user.socketId, data: null, type: 'ForceRemoved', typeValue: true });
                  }} style={{ height: 20, width: 20, resizeMode: 'contain', cursor: 'pointer', marginLeft: 7 }} /> : null}

                </div> :
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
                    <img src={user.isVideoOff ? video_off : video_on_parti} style={{ height: 20, width: 20, resizeMode: 'contain', cursor: isCallCreator ? 'pointer' : 'default', marginLeft: 7 }} />
                    <img src={user.isMute ? mic_parti : mic_on_parti} style={{ height: 20, width: 20, resizeMode: 'contain', cursor: isCallCreator ? 'pointer' : 'default', marginLeft: 7 }} />
                  </div>
              }




            </div>

            {
              index < displayParticipantArr.length - 1 ? <hr className='hrstyle'></hr> : null
            }
          </div>

        ))}
      </div>
    </div>
  );
});

export default DisplayParticipantComponent;
