// import React from 'react';
// import { useState, useEffect } from 'react';

import io from 'socket.io-client';
const socket = io('http://localhost:5000');

export function sendMessageToServer(tempObj,socket) {
  //console.log("Function from another file",socket.id);
  socket.emit('BE-addupdate', { socketId: socket.id, data: tempObj});
}
