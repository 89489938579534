import { CommunicationIdentityClient } from '@azure/communication-identity';

import { getConnectionStringUrl } from '../socket';
export const getAcsToken = async () => {
  const identityClient = new CommunicationIdentityClient(getConnectionStringUrl());
  const user = await identityClient.createUser();
  const tokenResponse = await identityClient.getToken(user, ["voip"]);
  return {
    userIdServer: user.communicationUserId,
    token: tokenResponse.token
  };
};
