import { CallClient, VideoStreamRenderer, LocalVideoStream, CallingCommunicationError, CallAutomationClient, Features } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { RoomsClient } from '@azure/communication-rooms';
import { CommunicationIdentityClient } from '@azure/communication-identity';
import socket, { getBaseUrl, getConnectionStringUrl, getSoundStatus } from '../socket';
const roomsClient = new RoomsClient(getConnectionStringUrl());
export async function createRoom(userId,callBack) {
  console.log("Function from another file",socket.id);

  try {
    // create identities for users
    const identityClient = new CommunicationIdentityClient(getConnectionStringUrl());
    const user1 = await identityClient.createUserAndToken(["voip"]);
    const participants = [
      {
        id: user1.user,
        role: "Presenter",
      },
    ];

    // Create a room
    var validFrom = new Date(Date.now());
    var validUntil = new Date(validFrom.getTime() + 60 * 60 * 1000);
    var pstnDialOutEnabled = false;

    const createRoomOptions = {
      validFrom,
      validUntil,
      pstnDialOutEnabled,
      participants
    };

    const createRoomResponse = await roomsClient.createRoom(createRoomOptions);
   let acsRoomId = createRoomResponse.id;
    console.log('-----createRoomResponse------', createRoomResponse.id);
    // add participant to room
    try {
      await roomsClient.addOrUpdateParticipants(createRoomResponse.id, [
        {
          id: { communicationUserId: userId },
          role: 'Attendee',
        },
      ]);

      let rndNumber = new Date().getTime()
    let adminlink = getBaseUrl() + '?acsusrt=1&rmid=' + acsRoomId + '&scrtid=' + rndNumber

    let rndNumberSeller = new Date().getTime() + 10
    let sellerLink = getBaseUrl() + '?acsusrt=2&rmid=' + acsRoomId + '&scrtid=' + rndNumberSeller

    console.log('---adminlink----', adminlink)
    console.log('---sellerlink----', sellerLink)

    let tempObj = {
      acsRoomId:acsRoomId,
      adminlink:adminlink,
      sellerLink:sellerLink
    }

  callBack(tempObj)

    } catch (error) {
      console.log('--error from participant add-- ', error);
    }
  } catch (error) {
    console.error(error);
  }

  
}
export async function addParticipant(acsRoomId,userId) {
  try {
    await roomsClient.addOrUpdateParticipants(acsRoomId, [
      {
        id: { communicationUserId: userId },
        role: 'Attendee',
      },
    ]);
    console.log(userId, 'Participant added or updated successfully', acsRoomId);
  } catch (error) {
    console.log('--error', error);
  }
}